define("song-text-book/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lnzZUML/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"header\",[],[[],[]]],[0,\"\\n\"],[2,\"page content \"],[0,\"\\n\"],[7,\"main\"],[11,\"role\",\"main\"],[11,\"class\",\"flex-shrink-0 p-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n        \"],[1,[23,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/application.hbs"
    }
  });

  _exports.default = _default;
});