define("song-text-book/cldrs/hr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "hr",
    "pluralRuleFunction": function (n, ord) {
      var s = String(n).split("."),
          i = s[0],
          f = s[1] || "",
          v0 = !s[1],
          i10 = i.slice(-1),
          i100 = i.slice(-2),
          f10 = f.slice(-1),
          f100 = f.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) || f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14) ? "few" : "other";
    },
    "fields": {
      "year": {
        "displayName": "godina",
        "relative": {
          "0": "ove godine",
          "1": "sljedeće godine",
          "-1": "prošle godine"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} godinu",
            "few": "za {0} godine",
            "other": "za {0} godina"
          },
          "past": {
            "one": "prije {0} godinu",
            "few": "prije {0} godine",
            "other": "prije {0} godina"
          }
        }
      },
      "year-short": {
        "displayName": "g.",
        "relative": {
          "0": "ove god.",
          "1": "sljedeće god.",
          "-1": "prošle god."
        },
        "relativeTime": {
          "future": {
            "one": "za {0} g.",
            "few": "za {0} g.",
            "other": "za {0} g."
          },
          "past": {
            "one": "prije {0} g.",
            "few": "prije {0} g.",
            "other": "prije {0} g."
          }
        }
      },
      "month": {
        "displayName": "mjesec",
        "relative": {
          "0": "ovaj mjesec",
          "1": "sljedeći mjesec",
          "-1": "prošli mjesec"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} mjesec",
            "few": "za {0} mjeseca",
            "other": "za {0} mjeseci"
          },
          "past": {
            "one": "prije {0} mjesec",
            "few": "prije {0} mjeseca",
            "other": "prije {0} mjeseci"
          }
        }
      },
      "month-short": {
        "displayName": "mj.",
        "relative": {
          "0": "ovaj mj.",
          "1": "sljedeći mj.",
          "-1": "prošli mj."
        },
        "relativeTime": {
          "future": {
            "one": "za {0} mj.",
            "few": "za {0} mj.",
            "other": "za {0} mj."
          },
          "past": {
            "one": "prije {0} mj.",
            "few": "prije {0} mj.",
            "other": "prije {0} mj."
          }
        }
      },
      "day": {
        "displayName": "dan",
        "relative": {
          "0": "danas",
          "1": "sutra",
          "2": "prekosutra",
          "-2": "prekjučer",
          "-1": "jučer"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} dan",
            "few": "za {0} dana",
            "other": "za {0} dana"
          },
          "past": {
            "one": "prije {0} dan",
            "few": "prije {0} dana",
            "other": "prije {0} dana"
          }
        }
      },
      "day-short": {
        "displayName": "d.",
        "relative": {
          "0": "danas",
          "1": "sutra",
          "2": "prekosutra",
          "-2": "prekjučer",
          "-1": "jučer"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} dan",
            "few": "za {0} dana",
            "other": "za {0} dana"
          },
          "past": {
            "one": "prije {0} dan",
            "few": "prije {0} dana",
            "other": "prije {0} dana"
          }
        }
      },
      "hour": {
        "displayName": "sat",
        "relative": {
          "0": "ovaj sat"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} sat",
            "few": "za {0} sata",
            "other": "za {0} sati"
          },
          "past": {
            "one": "prije {0} sat",
            "few": "prije {0} sata",
            "other": "prije {0} sati"
          }
        }
      },
      "hour-short": {
        "displayName": "h",
        "relative": {
          "0": "ovaj sat"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} h",
            "few": "za {0} h",
            "other": "za {0} h"
          },
          "past": {
            "one": "prije {0} h",
            "few": "prije {0} h",
            "other": "prije {0} h"
          }
        }
      },
      "minute": {
        "displayName": "minuta",
        "relative": {
          "0": "ova minuta"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} minutu",
            "few": "za {0} minute",
            "other": "za {0} minuta"
          },
          "past": {
            "one": "prije {0} minutu",
            "few": "prije {0} minute",
            "other": "prije {0} minuta"
          }
        }
      },
      "minute-short": {
        "displayName": "min",
        "relative": {
          "0": "ova minuta"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} min",
            "few": "za {0} min",
            "other": "za {0} min"
          },
          "past": {
            "one": "prije {0} min",
            "few": "prije {0} min",
            "other": "prije {0} min"
          }
        }
      },
      "second": {
        "displayName": "sekunda",
        "relative": {
          "0": "sad"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} sekundu",
            "few": "za {0} sekunde",
            "other": "za {0} sekundi"
          },
          "past": {
            "one": "prije {0} sekundu",
            "few": "prije {0} sekunde",
            "other": "prije {0} sekundi"
          }
        }
      },
      "second-short": {
        "displayName": "s",
        "relative": {
          "0": "sad"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} s",
            "few": "za {0} s",
            "other": "za {0} s"
          },
          "past": {
            "one": "prije {0} s",
            "few": "prije {0} s",
            "other": "prije {0} s"
          }
        }
      }
    },
    "numbers": {
      "decimal": {
        "long": [[1000, {
          "one": ["0 tisuća", 1],
          "few": ["0 tisuće", 1],
          "other": ["0 tisuća", 1]
        }], [10000, {
          "one": ["00 tisuća", 2],
          "few": ["00 tisuće", 2],
          "other": ["00 tisuća", 2]
        }], [100000, {
          "one": ["000 tisuća", 3],
          "few": ["000 tisuće", 3],
          "other": ["000 tisuća", 3]
        }], [1000000, {
          "one": ["0 milijun", 1],
          "few": ["0 milijuna", 1],
          "other": ["0 milijuna", 1]
        }], [10000000, {
          "one": ["00 milijun", 2],
          "few": ["00 milijuna", 2],
          "other": ["00 milijuna", 2]
        }], [100000000, {
          "one": ["000 milijun", 3],
          "few": ["000 milijuna", 3],
          "other": ["000 milijuna", 3]
        }], [1000000000, {
          "one": ["0 milijarda", 1],
          "few": ["0 milijarde", 1],
          "other": ["0 milijardi", 1]
        }], [10000000000, {
          "one": ["00 milijarda", 2],
          "few": ["00 milijarde", 2],
          "other": ["00 milijardi", 2]
        }], [100000000000, {
          "one": ["000 milijarda", 3],
          "few": ["000 milijarde", 3],
          "other": ["000 milijardi", 3]
        }], [1000000000000, {
          "one": ["0 bilijun", 1],
          "few": ["0 bilijuna", 1],
          "other": ["0 bilijuna", 1]
        }], [10000000000000, {
          "one": ["00 bilijun", 2],
          "few": ["00 bilijuna", 2],
          "other": ["00 bilijuna", 2]
        }], [100000000000000, {
          "one": ["000 bilijun", 3],
          "few": ["000 bilijuna", 3],
          "other": ["000 bilijuna", 3]
        }]],
        "short": [[1000, {
          "one": ["0 tis'.'", 1],
          "few": ["0 tis'.'", 1],
          "other": ["0 tis'.'", 1]
        }], [10000, {
          "one": ["00 tis'.'", 2],
          "few": ["00 tis'.'", 2],
          "other": ["00 tis'.'", 2]
        }], [100000, {
          "one": ["000 tis'.'", 3],
          "few": ["000 tis'.'", 3],
          "other": ["000 tis'.'", 3]
        }], [1000000, {
          "one": ["0 mil'.'", 1],
          "few": ["0 mil'.'", 1],
          "other": ["0 mil'.'", 1]
        }], [10000000, {
          "one": ["00 mil'.'", 2],
          "few": ["00 mil'.'", 2],
          "other": ["00 mil'.'", 2]
        }], [100000000, {
          "one": ["000 mil'.'", 3],
          "few": ["000 mil'.'", 3],
          "other": ["000 mil'.'", 3]
        }], [1000000000, {
          "one": ["0 mlr'.'", 1],
          "few": ["0 mlr'.'", 1],
          "other": ["0 mlr'.'", 1]
        }], [10000000000, {
          "one": ["00 mlr'.'", 2],
          "few": ["00 mlr'.'", 2],
          "other": ["00 mlr'.'", 2]
        }], [100000000000, {
          "one": ["000 mlr'.'", 3],
          "few": ["000 mlr'.'", 3],
          "other": ["000 mlr'.'", 3]
        }], [1000000000000, {
          "one": ["0 bil'.'", 1],
          "few": ["0 bil'.'", 1],
          "other": ["0 bil'.'", 1]
        }], [10000000000000, {
          "one": ["00 bil'.'", 2],
          "few": ["00 bil'.'", 2],
          "other": ["00 bil'.'", 2]
        }], [100000000000000, {
          "one": ["000 bil'.'", 3],
          "few": ["000 bil'.'", 3],
          "other": ["000 bil'.'", 3]
        }]]
      }
    }
  }];
  _exports.default = _default;
});