define("song-text-book/translations/hr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app.footer.header": "Pravno",
    "app.home.commingsoon": "Dolazi uskoro!",
    "app.home.lead.txt": "Ako tražite aplikaciju za brzo i jednostavno\nprikazivanje tekstova i akorda, i to \noptimiranu za nastupe uživo, onda ste na pravom mjestu!",
    "app.home.prop.autotranspose": "Automatska promjena akorda",
    "app.home.prop.difformats": "Podržavanje različitih formata",
    "app.home.prop.easyadmin": "Brzo i jednostavno održavanje tekstova",
    "app.home.prop.export": "Spremanje datoteka za backup",
    "app.home.prop.import": "Unos postojećih datoteka",
    "app.home.prop.managbooks": "Postavljanje tekstova u virtualne knjige i setove",
    "app.home.prop.optimized": "Optimalno prikazivanje tekstova",
    "app.home.prop.quicknav": "Brza i pregledna navigacija ",
    "app.home.testapp.desc": "Ako želite testirati prije kupnje pune verzije, imamo i malu verziju naše aplikacije.",
    "app.home.testapp.limit": "Mala aplikacija ograničena je na 10 pjesama, 3 knjige i 3 seta, ali sasvim dovoljno da steknete prve dojmove!",
    "app.login.activeuser-header": "Već imate račun",
    "app.login.email": "E-mail",
    "app.login.login": "Pristup",
    "app.login.newuser-header": "Novi korisnik",
    "app.login.password": "Lozinka",
    "app.login.register": "Prijava",
    "app.login.repeatpassword": "Ponovite Lozinku",
    "app.manual.1-start.lead": "Pogledajmo sve funkcije aplikacije Song TextBook",
    "app.manual.1-start.step1": "Na prvom startu aplikacije vidimo stranicu postavki, ovdje možete promijeniti različite postavke aplikacije, a više kliknite ovdje",
    "app.manual.1-start.step1-1": "Nakon spremanja postavki",
    "app.manual.1-start.step1-2": " kliknite \"natrag\"",
    "app.manual.1-start.step1-3": ", i vidimo početnu stranicu",
    "app.manual.1-start.step1-android": "Ako ste android korisnik imajte na umu, pri prvom pokretanju, aplikacija traži dozvolu za čitanje i pisanje  u datoteku \"Dokumenati\" , POTREBNI su nam za import i backup (sigurnosna kopija) !!!",
    "app.manual.1-start.step1-img.img1": "Glavni izbornik",
    "app.manual.1-start.step1-img.img2": "Ručno dodajte novu pjesmu",
    "app.manual.1-start.step1-img.img3": "Importirajte svoje tekstove pjesama",
    "app.manual.1-start.step1-img.img4": "Dodajte primjer pjesme: Ako želite vidjeti kako to izgleda ako imate\ntekstove pjesama u aplikaciji ili se želite poigrati s lažnim pjesmama. Kliknite ovdje i isprobajte, možete ukloniti sve uzorke pjesama iz glavnog izbornika \"Održavanje pjesama\"  odaberite sve i izbrisati ih.",
    "app.manual.1-start.step2": "U glavnom izborniku imamo poveznice na različite stranice",
    "app.manual.1-start.step2-img.img1": "Ovaj je izbornik za manualno dodavanje novih tekstova pjesama. Za više informacija kliknite ovdje",
    "app.manual.1-start.step2-img.img2": "Ovdje možete napraviti nove \"Knjige\", dodati pjesme u Knjige, izbrisati pjesme itd. Za više detalja kliknite ovdje",
    "app.manual.1-start.step2-img.img3": "Otvara stranicu za upravljanje setovima. ovdje imate mogućnost organizirati svoje setove i poredati pjesme kako želite, za više o tome kliknite ovdje.",
    "app.manual.1-start.step2-img.img4": "Ovaj je izbornik za upravljanje virtualnim knjigama. Ovdje možete dodati različite virtualne knjige (npr. MyFavorits, Rock, Pop ...), za više detalja o knjigama kliknite ovdje.",
    "app.manual.1-start.step2-img.img5": "Ovdje možete unijeti pjesme koje vec imate. Možete uvesti različite formate dokumenata (txt, pdf, img, docx ...) Za više detalja kliknite ovdje",
    "app.manual.1-start.step2-img.img6": "Za sigurnosnu kopiju (izvoz) koristite ovu stranicu. Izvozi sve pjesme u TXT formatu i stvara ZIP datoteku sa svim pjesmama iznutra. Za više detalja kliknite ovdje",
    "app.manual.1-start.step2-img.img7": "Na stranici postavki možete konfigurirati aplikaciju prema vašim potrebama. Za više informacija kliknite ovdje",
    "app.manual.1-start.step2-img.img8": "Link na web stranicu \"www.songtextbook.com\"",
    "app.manual.1-start.title": "Dobrodošli u priručnik od Song TextBook-a",
    "app.manual.2-newsong.lead": "Kako dodati novu pjesmu?",
    "app.manual.2-newsong.step1": "Ako u aplikaciji nema pjesama, vidjet ćete ovu stranicu.\nKliknite na \"Dodaj novu pjesmu\".",
    "app.manual.2-newsong.step2": "Pjesmu možete dodati i preko glavnog izbornika: kliknite na ikonu      \ni otvorite glavni izbornik, zatim kliknite na „Add new song“.\n",
    "app.manual.2-newsong.step3": "Na stranici \"Add new song\" obavezna polja su „Naziv“ i „Tekst“,\nsva druga polja su neobavezna.",
    "app.manual.2-newsong.step4": "Tekst možete upisati sami.",
    "app.manual.2-newsong.step5": "Ako upišete akorde kao u primjeru ispod ( npr. [C] ), biti će prikazani povrh teksta.",
    "app.manual.2-newsong.step6": "Bit će dodatno uputstvo u kojem opisujemo kako možete oblikovati tekst",
    "app.manual.2-newsong.step7": "Sada morate spremiti, kliknite na ikonu za spremiti\nIdite natrag na početnu stranicu i kliknite na pjesmu.",
    "app.manual.2-newsong.step8-1": "Također možete odabrati datoteku ",
    "app.manual.2-newsong.step8-2": "ili napraviti fotografiju teksta  pjesme",
    "app.manual.2-newsong.title": "Dodaj novu pjesmu",
    "app.manual.3-books.title": "Održavanje Knjiga",
    "app.manual.4-sets.title": "Održavanje setova",
    "app.manual.5-import.lead": "Možete importirati datoteke u različitim formatima (txt, doc, docx, pdf, jpeg, bmp, heic, chordpro, xsl, xslx,...) Slijedeći koraci su isti za iPad ili iPhone.",
    "app.manual.5-import.select-os": "Odaberite operativni sustav",
    "app.manual.5-import.step1": "Kako importirati moje tekstove koje već imam?\nPrvo, ubacite ih sve na uređaj putem iCloud, GoogleDrive ili iTunes …\nZatim otvorite aplikaciju “Files”",
    "app.manual.5-import.step10": "Odite na početak i vidjet ćete importirane pjesme.",
    "app.manual.5-import.step2": "Kad kliknete na “On My iPad” (ili iPhone) vidite mapu “SongTextBook”.",
    "app.manual.5-import.step3": "Imate unutar \"SongTextBook\" dvije mape \"Export\" i \"Import\", kao što pretpostavljate, morate otvoriti Import mapu i premjestiti ili kopirati svoje datoteke ovdje.",
    "app.manual.5-import.step4-1": "Dakle, sve datoteke su u import mapi.",
    "app.manual.5-import.step4-2": "Sada otvorite aplikaciju Song TextBook",
    "app.manual.5-import.step4-3": "Kliknite na “Import Songs”.",
    "app.manual.5-import.step5": "Kliknite na \"Files\" i odite do svojih pjesama.",
    "app.manual.5-import.step6": "Kliknite na \"Import songs\" (ili podmapu) u kojoj su datoteke.",
    "app.manual.5-import.step7": "Kliknite na \" Download all new \" ili možete odabrati pojedinačno datoteku.",
    "app.manual.5-import.step8": "Ako već imate pjesme s istim nazivom u SongTextBook app, one će biti zamijenjene!!!",
    "app.manual.5-import.step9": "Nakon što je preuzimanje gotovo, vidjet ćete na desnoj strani plavu oblak ikonu.",
    "app.manual.5-import.title": "Import",
    "app.manual.6-export.lead": "Kako eksportirati (backup) moje pjesme?",
    "app.manual.6-export.select-os": "Odaberite operativni sustav",
    "app.manual.6-export.step1": "Otvorite glavni izbornik i kliknite na “Export songs”.",
    "app.manual.6-export.step10-1": "Kao što možete vidjeti nastaje nova mapa i jedna ZIP datoteka s svim pjesmama.",
    "app.manual.6-export.step10-2": "Gdje mogu pronaći svoju eksportiranu datoteku?",
    "app.manual.6-export.step11": "Otvorite App “Files” i odite na “On My iPad” zatim kliknite na SongTextBook.",
    "app.manual.6-export.step12": "Kliknite na mapu “Export Folder”.",
    "app.manual.6-export.step13": "I ovdje je naša nova mapa \"test 1\".",
    "app.manual.6-export.step14": "U novoj mapi su naše pjesme.",
    "app.manual.6-export.step15": "Možete ih kopirati ili premjestiti gdje god želite, na primjer na iCloud Drive.",
    "app.manual.6-export.step2": "Kliknite na “Files”.",
    "app.manual.6-export.step3": "Možete odabrati koje pjesme želite eksportirati ili kliknete na “Select all” .",
    "app.manual.6-export.step4": "Nakon što odaberete pjesme za eksport, kliknite na",
    "app.manual.6-export.step5": "Sada morate odabrati u koju mapu želite spremiti.\nTakođer možete napraviti novu mapu ili jednostavno kliknite na “Export Folder”.",
    "app.manual.6-export.step6": "",
    "app.manual.6-export.step7": "",
    "app.manual.6-export.step8": "Kliknite na novu mapu. ",
    "app.manual.6-export.step9": "Da biste spremili u novu mapu, kliknite na ikonu ",
    "app.manual.6-export.title": "Eksport",
    "app.manual.7-settings.title": "Postavke",
    "app.manual.8-helpful.filter": "Filtrirajte pjesme",
    "app.manual.8-helpful.navigation": "Izbornik pjesme",
    "app.manual.8-helpful.title": "Korisni videozapisi",
    "app.manual.8-helpful.transpose": "Transponirajte akord pjesme",
    "app.manual.nav.export": "Pjesme eksportirati ",
    "app.manual.nav.helpful": "Koristno",
    "app.manual.nav.import": "Preuzimanje Pjesama",
    "app.manual.nav.managbooks": "Održavanje knjiga",
    "app.manual.nav.managesets": "Održavanje setova",
    "app.manual.nav.mangesongs": "Održavanje pjesama",
    "app.manual.nav.menu": "Izbornik",
    "app.manual.nav.newsong": "Nova pjesma",
    "app.manual.nav.settings": "Sve o postavkama",
    "app.manual.nav.start": "Početak",
    "app.navbar.gallery": "Galerija",
    "app.navbar.home": "Početna",
    "app.navbar.impressum": "O nama",
    "app.navbar.login": "Prijava",
    "app.navbar.logout": "Odjava",
    "app.navbar.manual": "Priručnik",
    "app.navbar.privacy": "Pravila o privatnosti",
    "app.navbar.support": "Podrška",
    "app.support.autoId": "auto ID :",
    "app.support.confirm": "Potvrdi",
    "app.support.fromular.email": "Vaša email adresa",
    "app.support.fromular.msg": "Poruka",
    "app.support.fromular.name": "Ime",
    "app.support.fromular.submit": "Pošalji",
    "app.support.lead": "Ako imate bilo kakvih pitanja u vezi s Song TestBook APP, kontaktirajte nas!",
    "app.support.ouremial": "ovdje upišite e-mail ponovo",
    "app.support.protectemail": "Za zaštitu od SPAM pošte morate unijeti auto ID!",
    "app.support.shownId": "unesi prikazan automatski ID"
  };
  _exports.default = _default;
});