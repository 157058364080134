define("song-text-book/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userEmail: '',
    userPwd: '',
    newUserEmail: '',
    newUserPwd: '',
    repeatNewUserPwd: '',
    actions: {
      onLogin() {//this.transitionToRoute("account");
      },

      onRegister() {//this.transitionToRoute("home");
      }

    }
  });

  _exports.default = _default;
});