define("song-text-book/adapters/application", ["exports", "emberfire/adapters/realtime-database"], function (_exports, _realtimeDatabase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/adapters/application.js
  var _default = _realtimeDatabase.default.extend({
    enablePersistence: true,
    persistenceSettings: {
      synchronizeTabs: true
    }
  });

  _exports.default = _default;
});