define("song-text-book/templates/manual/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1G0deuW8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"contanier-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center p-4\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"app.manual.nav.settings\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"video-box\"],[9],[0,\"\\n        \"],[1,[29,\"videojs-player\",null,[[\"src\",\"type\",\"fluid\"],[\"/assets/video/settings.mp4\",\"video/mp4\",true]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/manual/settings.hbs"
    }
  });

  _exports.default = _default;
});