define("song-text-book/services/viewer-manager", ["exports", "ember-pdfjs/services/viewer-manager"], function (_exports, _viewerManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _viewerManager.default;
    }
  });
});