define("song-text-book/controllers/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    supportEmail: '',
    userInput: '',
    showContact: Ember.computed(function () {
      return this.supportEmail != '';
    }),
    generatedId: Ember.computed(function () {
      return this.makeId(6);
    }),

    makeId(length) {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;

      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

    actions: {
      confirm() {
        if (this.userInput == this.generatedId) {
          this.model.forEach(contact => {
            this.supportEmail = contact.get('email');
          });
        }

        this.set('userInput', '');
        this.notifyPropertyChange('userInput');
        this.notifyPropertyChange('showContact');
        this.notifyPropertyChange('generatedId');
      }

    }
  });

  _exports.default = _default;
});