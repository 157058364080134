define("song-text-book/controllers/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    images: [],

    init() {
      for (let index = 0; index < 16; index++) {
        var img = {};
        img.index = index;
        img.name = "stb" + Number(index) + ".png";
        img.alt = "alt " + index.toString();
        img.caption = "caption " + index.toString();
        ;
        img.desc = "desc " + index.toString();
        ;
        this.images.push(img); //console.log(this.images);
      }
    }

  });

  _exports.default = _default;
});