define("song-text-book/translations/de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app.footer.header": "Rechtliches",
    "app.home.commingsoon": "Kommt bald!",
    "app.home.lead.txt": "Wenn Sie nach einer App suchen, die es Ihnen ermöglicht, schnell und einfach\nIhre Songtexte und Akkorde zu verwalten und die\nfür Live-Auftritte optimiert ist, dann sind Sie hier genau richtig!",
    "app.home.prop.autotranspose": "Automatisches Transponieren von Songakkorden",
    "app.home.prop.difformats": "Unterstützung verschiedener Formate",
    "app.home.prop.easyadmin": "Schnelles und einfaches Verwalten von Texten",
    "app.home.prop.export": "Exportieren und sichern",
    "app.home.prop.import": "Import von bereits vorhandenen Dateien",
    "app.home.prop.managbooks": "Verwalten von virtuellen Büchern und Sets",
    "app.home.prop.optimized": "Optimierte Darstellung von Texten",
    "app.home.prop.quicknav": "Schnelle und übersichtliche Navigation",
    "app.home.testapp.desc": "Wenn Sie es vor dem Kauf der Vollversion testen möchten, haben wir auch eine kleine Version unserer App.",
    "app.home.testapp.limit": "Die kleine App ist auf 10 Songs, 3 Bücher und 3 Sets limitiert, aber es reicht aus, um einen ersten Eindruck zu bekommen!",
    "app.login.activeuser-header": "Sie haben bereits ein Konto",
    "app.login.email": "E-mail",
    "app.login.login": "Anmelden",
    "app.login.newuser-header": "Neuer Benutzer",
    "app.login.password": "Passwort",
    "app.login.register": "Registrieren",
    "app.login.repeatpassword": "Passwort wiederholen",
    "app.manual.1-start.lead": "Werfen wir einen Blick auf alle Funktionen der Song TextBook App",
    "app.manual.1-start.step1": "Beim ersten Start der App wird die Seite mit den Einstellungen angezeigt. Hier können Sie verschiedene App-Einstellungen ändern. Weitere Informationen finden Sie hier",
    "app.manual.1-start.step1-1": "Nach dem Speichern der Einstellungen",
    "app.manual.1-start.step1-2": " klicken Sie auf \"Zurück\"",
    "app.manual.1-start.step1-3": ", und wir sehen die Startseite",
    "app.manual.1-start.step1-android": "Android User bitte beachten !!! Beim ersten Start bittet die App um die Erlaubnis zum Lesen und Schreiben in \"Dokumenten\", wir BRAUCHEN sie für den Import und das Backup !!!",
    "app.manual.1-start.step1-img.img1": "Hauptmenü",
    "app.manual.1-start.step1-img.img2": "Neuen Song manuell hinzufügen",
    "app.manual.1-start.step1-img.img3": "Importieren Sie Ihre Songtexte",
    "app.manual.1-start.step1-img.img4": "Beispielsongs hinzufügen: Wenn Sie sehen möchten, wie es aussieht, wenn Songtexte in der App vorhanden sind, oder einfach mal ausprobieren möchten, klicken Sie hier und probieren Sie es aus. Sie können alle Beispiel-Songs im Hauptmenü entfernen - \"Songs verwalten\"  alles auswählen und löschen.",
    "app.manual.1-start.step2": "Im Hauptmenü haben wir Links zu verschiedenen Seiten",
    "app.manual.1-start.step2-img.img1": "In diesem Menü können Sie neue Songtexte manuell hinzufügen. Für mehr Informationen klicken Sie hier",
    "app.manual.1-start.step2-img.img2": "Hier können Sie \"Bücher\" erstellen, Titel zu Büchern hinzufügen, Titel löschen usw. Für weitere Informationen klicken Sie hier",
    "app.manual.1-start.step2-img.img3": "Öffnet die Seite zum Verwalten von Sets. Auf dieser Seite haben Sie die Möglichkeit Ihre Sets zu organisieren und Songs nach Ihren Wünschen zu sortieren. Für weitere Informationen klicken Sie hier.",
    "app.manual.1-start.step2-img.img4": "In diesem Menü werden virtuelle Bücher verwaltet. Hier können Sie verschiedene virtuelle Bücher hinzufügen (z. B. MyFavorits, Rock, Pop ...), um weitere Informationen zu Büchern zu erhalten. Klicken Sie hier.",
    "app.manual.1-start.step2-img.img5": "Hier können Sie Songs importieren, die Sie fertig haben. Sie können verschiedene Dokumentformate importieren (txt, pdf, img, docx ...). Für weitere Informationen klicken Sie hier",
    "app.manual.1-start.step2-img.img6": "Zum Sichern (Exportieren) verwenden Sie diese Seite. Es exportiert alle Songs als TXT-Format und erstellt eine ZIP-Datei mit allen darin enthaltenen Songs. Für mehr Details klicken Sie hier",
    "app.manual.1-start.step2-img.img7": "Auf der Einstellungsseite können Sie die App nach Ihren Wünschen konfigurieren. Für mehr Informationen klicken Sie hier",
    "app.manual.1-start.step2-img.img8": "Link zur Webseite \"www.songtextbook.com\"",
    "app.manual.1-start.title": "Willkommen zum Song TextBook Handbuch",
    "app.manual.2-newsong.lead": "So fügen Sie ein neues Lied hinzu.",
    "app.manual.2-newsong.step1": "Wenn die App keine Songs hat, wird diese Seite angezeigt.\nKlick auf “Add new song”.",
    "app.manual.2-newsong.step2": "Oder Sie können es über das Hauptmenü hinzufügen: \nKlicken Sie auf das Menüsymbol und öffnen Sie das Hauptmenü.\nKlicken Sie auf „Add new song“",
    "app.manual.2-newsong.step3": "Auf der Seite „Add new song“ erforderliche Felder sind: Name und Text.\nAlle anderen Felder sind optional.",
    "app.manual.2-newsong.step4": "Sie können den Text selbst eingeben.",
    "app.manual.2-newsong.step5": "Wenn Sie die Akkorde über dem Text angezeigt haben wollen, \nschreiben Sie diese wie im unteren Beispiel (z.B. [C])",
    "app.manual.2-newsong.step6": "In einem zusätzlichen Abschnitt wird beschrieben, wie Sie den Text formatieren können.",
    "app.manual.2-newsong.step7": "Jetzt müssen Sie es speichern, klicken Sie auf das Speichern-Symbol\nNavigieren Sie zurück zur Startseite und klicken Sie in der Liste auf das Lied.",
    "app.manual.2-newsong.step8-1": "Sie können auch eine Datei auswählen ",
    "app.manual.2-newsong.step8-2": "oder ein Foto von Ihrem Songtext machen",
    "app.manual.2-newsong.title": "Neuen Song hinzufügen",
    "app.manual.3-books.title": "Bücher verwalten",
    "app.manual.4-sets.title": "Sets verwalten",
    "app.manual.5-import.lead": "Sie können Dateien in verschiedenen Formaten importieren (txt, doc, docx, pdf, jpeg, bmp, heic, chordpro, xsl, xslx,...). Die folgenden Schritte sind für iPad oder iPhone identisch.",
    "app.manual.5-import.select-os": "Betriebssystem auswählen",
    "app.manual.5-import.step1": "Wie importiere ich meine Songtexte, die ich bereits habe?\nZuerst bringen Sie sie alle auf das Gerät über iCloud, GoogleDrive oder iTunes…,\nwie auch immer, öffnen Sie dann die App “Files”",
    "app.manual.5-import.step10": "Gehen Sie zurück zum start und Sie werden importierte Songs sehen.",
    "app.manual.5-import.step2": "Wenn Sie auf “On My iPad” (oder iPhone) klicken, sehen Sie den Ordner “SongTextBook”, klicken sie darauf.",
    "app.manual.5-import.step3": "Sie haben in dem Ordner “SongTextBook” zwei Ordner “Export” und “Import“.\nWie Sie erraten können, müssen Sie den Import-Ordner öffnen und hier ihre Dateien verschieben oder kopieren.",
    "app.manual.5-import.step4-1": "Alle Dateien befinden sich im „Import Folder“ (sie können hier auch Unterordner erstellen).",
    "app.manual.5-import.step4-2": "Jetzt Öffnen Sie die Song TextBook App",
    "app.manual.5-import.step4-3": "Klicken Sie auf \"Import Songs\".",
    "app.manual.5-import.step5": "Klicken Sie auf \"Files\" und gehen Sie zu Ihren Songtextdateien.",
    "app.manual.5-import.step6": "Klicken Sie auf \"Import Folder \" (oder Unterordner), wo sich Ihre Dateien befinden.",
    "app.manual.5-import.step7": "Klicken Sie auf \"Download all new\" oder Sie können auch eine einzelne Datei auswählen.",
    "app.manual.5-import.step8": "Wenn Sie Songs mit dem gleichen Namen bereits in der SongTextBook App haben, \nwerden diese überschrieben!!!",
    "app.manual.5-import.step9": "Nachdem der Download abgeschlossen ist, wird rechts eine blaue Wolke zu sehen sein.",
    "app.manual.5-import.title": "Importieren ",
    "app.manual.6-export.lead": "Wie kann ich meine Songs exportieren (Backup)?",
    "app.manual.6-export.select-os": "Betriebssystem auswählen",
    "app.manual.6-export.step1": "Öffnen Sie das Hauptmenü und klicken Sie auf \"Export songs\".",
    "app.manual.6-export.step10-1": "Wie Sie sehen können, wird ein Ordner und eine ZIP-Datei mit allen Songs erstellt.",
    "app.manual.6-export.step10-2": "Wo ist meine exportierte Datei?",
    "app.manual.6-export.step11": "Öffnen Sie die App „Files“, gehen Sie auf \"On My iPad\" und klicken Sie auf SongTextBook.",
    "app.manual.6-export.step12": "Klicken Sie auf Export-Ordner",
    "app.manual.6-export.step13": "Und hier ist unser neuer Ordner \"test 1\".",
    "app.manual.6-export.step14": "In unserem neuen Ordner befinden sich unsere Songs.",
    "app.manual.6-export.step15": "Sie können diese kopieren oder verschieben, wohin immer Sie möchten, zum Beispiel auf iCloud Drive.",
    "app.manual.6-export.step2": "Klicken Sie auf “Files”.",
    "app.manual.6-export.step3": "Jetzt können Sie wählen, welche Songs Sie exportieren möchten oder Sie klicken auf \"Select all\".",
    "app.manual.6-export.step4": "Nachdem Sie Songs für den Export ausgewählt haben, klicken Sie auf",
    "app.manual.6-export.step5": "Jetzt müssen Sie auswählen, in welchem Ordner Sie es \"exportieren\" möchten,\nSie können auch einen neuen Ordner erstellen, wenn Sie möchten, \noder Sie klicken auf \"Export Folder\".",
    "app.manual.6-export.step6": "",
    "app.manual.6-export.step7": "",
    "app.manual.6-export.step8": "Klicken Sie auf einen neuen Ordner.",
    "app.manual.6-export.step9": "Um es in einem neuen Ordner zu speichern, klicken Sie auf das Symbol",
    "app.manual.6-export.title": "Export",
    "app.manual.7-settings.title": "Einstellungen",
    "app.manual.8-helpful.filter": "Songs filtern",
    "app.manual.8-helpful.navigation": "Song-Menü",
    "app.manual.8-helpful.title": "Hilfreiche Videos",
    "app.manual.8-helpful.transpose": "Transponieren Sie den Songakkord",
    "app.manual.nav.export": "Songs exportieren",
    "app.manual.nav.helpful": "Hilfreich",
    "app.manual.nav.import": "Songs importieren",
    "app.manual.nav.managbooks": "Bücher verwalten",
    "app.manual.nav.managesets": "Sets verwalten",
    "app.manual.nav.mangesongs": "Songs verwalten",
    "app.manual.nav.menu": "Menü",
    "app.manual.nav.newsong": "Neues Lied",
    "app.manual.nav.settings": "Alles über Einstellungen",
    "app.manual.nav.start": "Start",
    "app.navbar.gallery": "Galerie",
    "app.navbar.home": "Start",
    "app.navbar.impressum": "Impressum",
    "app.navbar.login": "Anmelden",
    "app.navbar.logout": "Abmelden",
    "app.navbar.manual": "Handbuch",
    "app.navbar.privacy": "Datenschutzerklärung",
    "app.navbar.support": "Support",
    "app.support.autoId": "Auto-ID :",
    "app.support.confirm": "Bestätigen",
    "app.support.fromular.email": "Ihr Email adresse",
    "app.support.fromular.msg": "Nachricht",
    "app.support.fromular.name": "Name",
    "app.support.fromular.submit": "Senden",
    "app.support.lead": "Wenn Sie Fragen zu Song TestBook APP haben, wenden Sie sich bitte an uns!",
    "app.support.ouremial": "E-Mail hier erneut eingeben",
    "app.support.protectemail": "Um uns vor Spam-Mails zu schützen, müssen Sie unsere Auto-ID erneut eingeben!",
    "app.support.shownId": "hier angezeigte Auto-ID eingeben"
  };
  _exports.default = _default;
});