define("song-text-book/router", ["exports", "song-text-book/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('home');
    this.route('manual', function () {
      this.route('start');
      this.route('how-add-new-song');
      this.route('how-manage-books');
      this.route('how-manage-sets');
      this.route('settings');
      this.route('export', function () {
        this.route('android');
        this.route('ios');
      });
      this.route('android', function () {});
      this.route('import', function () {
        this.route('ios');
        this.route('android');
      });
      this.route('how-manage-songs');
      this.route('helpful');
    });
    this.route('gallery');
    this.route('privacy');
    this.route('support');
    this.route('impressum');
    this.route('about');
    this.route('import', function () {});
    this.route('login');
    this.route('account');
  });
  var _default = Router;
  _exports.default = _default;
});