define("song-text-book/components/header", ["exports", "song-text-book/config/environment", "ember-intl/hydrate"], function (_exports, _environment, _hydrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    userIsLogged: false,

    getLanguageIndex(lanuage) {
      var ind = 0;

      if (lanuage == 'en') {
        ind = 0;
      } else if (lanuage == 'de') {
        ind = 1;
      } else if (lanuage == 'hr') {
        ind = 2;
      } //console.log("--lang ind", lanuage, ind);


      return ind;
    },

    activeLocale: Ember.computed.readOnly('intl.locale'),
    locales: Ember.computed(function () {
      return (0, _hydrate.lookupByFactoryType)('translations', modulePrefix).map(moduleName => moduleName.split('/').pop());
    }).readOnly(),
    selections: Ember.computed('locales.[]', 'activeLocale', function () {
      let active = Ember.get(this, 'activeLocale');
      return Ember.get(this, 'locales').map(locale => {
        var ind = this.getLanguageIndex(locale);
        return {
          index: ind,
          locale: locale,
          active: active.indexOf(locale) > -1
        };
      });
    }).readOnly(),
    actions: {
      changeLocale(locale) {
        return Ember.get(this, 'intl').set('locale', locale);
      }

    }
  });

  _exports.default = _default;
});