define("song-text-book/initializers/init-pdfjs-workersrc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals PDFJS */
  var _default = {
    name: 'init-pdfjs-workersrc',
    initialize: function () {
      PDFJS.workerSrc = '/assets/pdf.worker.js';
    }
  };
  _exports.default = _default;
});