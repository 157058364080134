define("song-text-book/components/pdf-document", ["exports", "ember-pdfjs/components/pdf-document"], function (_exports, _pdfDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pdfDocument.default;
    }
  });
});