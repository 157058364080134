define("song-text-book/templates/manual/import/android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62Lp+YJl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"contanier-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n         \"],[7,\"h3\"],[11,\"class\",\"text-main m-4\"],[9],[1,[29,\"t\",[\"app.manual.5-import.title\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"src\",\"/assets/images/underconstruction.png\"],[11,\"style\",\"max-height: 250px;\"],[11,\"class\",\"img-fluid\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/manual/import/android.hbs"
    }
  });

  _exports.default = _default;
});