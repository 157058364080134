define("song-text-book/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uAwuM/T/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container text-white\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container rounded mb-4 p-4 bg-dark3 text-white\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"app.login.activeuser-header\"],null],false],[10],[0,\"\\n        \"],[7,\"form\"],[11,\"id\",\"fs-frm1\"],[11,\"name\",\"simple-contact-form\"],[11,\"accept-charset\",\"utf-8\"],[11,\"method\",\"post\"],[9],[0,\"\\n            \"],[7,\"fieldset\"],[11,\"id\",\"fs-frm-inputs\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n                    \"],[7,\"label\"],[11,\"for\",\"email-address\"],[9],[1,[29,\"t\",[\"app.login.email\"],null],false],[10],[0,\"\\n                    \\n                    \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"value\",\"required\",\"autofocus\"],[\"email\",\"form-control\",[25,[\"userEmail\"]],\"true\",\"\"]]],false],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n                    \"],[7,\"label\"],[11,\"for\",\"password\"],[9],[1,[29,\"t\",[\"app.login.password\"],null],false],[10],[0,\"\\n                     \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"value\",\"required\",\"autofocus\"],[\"password\",\"form-control\",[25,[\"userPwd\"]],\"true\",\"\"]]],false],[0,\"\\n                \"],[10],[0,\"\\n\\n            \"],[10],[0,\"\\n            \"],[7,\"input\"],[11,\"class\",\"btn btn-primary\"],[12,\"value\",[30,[[29,\"t\",[\"app.login.login\"],null]]]],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"onLogin\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/login.hbs"
    }
  });

  _exports.default = _default;
});