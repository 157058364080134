define("song-text-book/templates/manual/how-manage-sets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s9HDKCjI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"contanier-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center p-4\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"app.manual.nav.managesets\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"video-box\"],[9],[0,\"\\n        \"],[1,[29,\"videojs-player\",null,[[\"src\",\"fluid\",\"type\"],[\"/assets/video/manage-sets.mp4\",true,\"video/mp4\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/manual/how-manage-sets.hbs"
    }
  });

  _exports.default = _default;
});