define("song-text-book/routes/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      //return this.store.findAll('contact');
      return this.store.query('contact', {
        orderBy: 'department',
        equalTo: 'support'
      });
    } // setupController: function(controller, models) {
    //     controller.set('contact', models.contact);
    // }


  });

  _exports.default = _default;
});