define("song-text-book/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app.footer.header": "Legal",
    "app.home.commingsoon": "Is comming soon!",
    "app.home.lead.txt": "If you are looking for an app which makes it possible to manage your song lyrics and chords in a fast and easy way, also optimized for live performance, this is your app!",
    "app.home.prop.autotranspose": "Automatically transpose of song chords",
    "app.home.prop.difformats": "Supporting different formats",
    "app.home.prop.easyadmin": "Easy and fast administration of lyrics",
    "app.home.prop.export": "Export and backup",
    "app.home.prop.import": "Importing of already existing files",
    "app.home.prop.managbooks": "Managing of virtual books and sets",
    "app.home.prop.optimized": "Optimized presentation of lyrics",
    "app.home.prop.quicknav": "Quick and easy navigation",
    "app.home.testapp.desc": "If you want to test it befor buy full version, we also have small version of our App.",
    "app.home.testapp.limit": "The small App is limited up to 10 songs, 3 books and 3 sets, but it is enough to get first impression!",
    "app.login.activeuser-header": "You already have an account",
    "app.login.email": "E-mail",
    "app.login.login": "Login",
    "app.login.newuser-header": "New user",
    "app.login.password": "Password",
    "app.login.register": "Register",
    "app.login.repeatpassword": "Repeat Password",
    "app.manual.1-start.lead": "Let's take a look on all functions of Song TextBook app",
    "app.manual.1-start.step1": "On the first app start we see settings page, here you can change different app settings, for more click here",
    "app.manual.1-start.step1-1": "After saving settings",
    "app.manual.1-start.step1-2": ", click on \"back\"",
    "app.manual.1-start.step1-3": ", and we see the start page",
    "app.manual.1-start.step1-android": "Android user please note!!! On first start, app is asking for permissions for reading and writing in \"Documents\", we NEED it for import and backup!!!",
    "app.manual.1-start.step1-img.img1": "Main menu",
    "app.manual.1-start.step1-img.img2": "Add new song manually",
    "app.manual.1-start.step1-img.img3": "Import your song texts",
    "app.manual.1-start.step1-img.img4": "Add sample songs: If you want to see how it looks if you have some \nsong lyrics in app, or you want to play with fake songs let's klick here and try it, you can remove all sample songs in main menu  \"Mange songs\" select all and delete it. ",
    "app.manual.1-start.step2": "In main menu we have links to different pages",
    "app.manual.1-start.step2-img.img1": "This menu is for adding new song lyrics manually. For more information click here",
    "app.manual.1-start.step2-img.img2": "Here you can create \"Books\", add songs to Books, delete songs etc. For more details click here",
    "app.manual.1-start.step2-img.img3": "A page for managing sets will be opened. On this page you have a possibility to organize yours sets and order songs how you want, for more about it click here.",
    "app.manual.1-start.step2-img.img4": "This menu is for managing virtual books. Here you can add different virtual books (e.g MyFavorits, Rock, Pop ...), for more details about books, klick here.",
    "app.manual.1-start.step2-img.img5": "Here you can import songs that you already have. You can import different document formats (txt, pdf, img, docx ...), for more details click here",
    "app.manual.1-start.step2-img.img6": "For backup (export) use this page. It export all songs as TXT format and it creates a ZIP file with all songs inside. For more details click here",
    "app.manual.1-start.step2-img.img7": "On settings page you can configure the app according to your needs. For more information click here",
    "app.manual.1-start.step2-img.img8": "Link to website \"www.songtextbook.com\"",
    "app.manual.1-start.title": "Welcome to Song TextBook manual",
    "app.manual.2-newsong.lead": "How to add a new song?",
    "app.manual.2-newsong.step1": "If there are no songs in the app, you will see this page,\nclick on “Add new song”.",
    "app.manual.2-newsong.step2": "Or you can add it over main menu: click on menu icon and open main menu,\nclick on Add new song.",
    "app.manual.2-newsong.step3": "On the page “Add new song”, required fields are Name and Text, \nall other fields are optional.",
    "app.manual.2-newsong.step4": "You can type text by yourself.",
    "app.manual.2-newsong.step5": "If you put chord like in the example below ( [C] ), it will be shown over the text.",
    "app.manual.2-newsong.step6": "There will be an extra section where we describe how you can format the text",
    "app.manual.2-newsong.step7": "Now you have to save it, click on save icon\nNavigate back to start page and click on Song in the list.",
    "app.manual.2-newsong.step8-1": "You can also pick a file ",
    "app.manual.2-newsong.step8-2": "or make photo of your song text ",
    "app.manual.2-newsong.title": "Add new song",
    "app.manual.3-books.title": "Manage books",
    "app.manual.4-sets.title": "Manage sets",
    "app.manual.5-import.lead": "You can Import files in different formats (txt, doc, docx, pdf, jpeg, bmp, heic, chordpro, xsl, xslx, ...) follow steps are the same for iPad or iPhone.",
    "app.manual.5-import.select-os": "Select operating system",
    "app.manual.5-import.step1": "How to import my song texts that I already have?\nFirst, bring all of them to device over iCloud, GoogleDrive or iTunes …, however,\nthen open the App “Files”",
    "app.manual.5-import.step10": "Navigate back to start and you will see imported songs.",
    "app.manual.5-import.step2": "When you click “On My iPad” (or iPhone) you see folder “SongTextBook”, click on it also.",
    "app.manual.5-import.step3": "Inside “SongTextBook” you have two folders “Export” and “Import”, as you can guess, you have to open Import Folder and move or copy your files here.",
    "app.manual.5-import.step4-1": "All files are in the import folder (if you want, you can also create subfolder here).",
    "app.manual.5-import.step4-2": "Now open Song TextBook App ",
    "app.manual.5-import.step4-3": "Click on “Import Songs”.",
    "app.manual.5-import.step5": "Click on “Files” and navigate to your song text files.",
    "app.manual.5-import.step6": "Click on “Import Folder” to go to your files.",
    "app.manual.5-import.step7": "Click on “Download all new” or you can choose single file.",
    "app.manual.5-import.step8": "If you already have songs with the same name in SongTextBook App, they will be overwritten!!! ",
    "app.manual.5-import.step9": "After downloading is finished, you’ll see a blue cloud icon on the right.",
    "app.manual.5-import.title": "Import ",
    "app.manual.6-export.lead": "How to export (backup) my Songs?",
    "app.manual.6-export.select-os": "Select operating system",
    "app.manual.6-export.step1": "Open main menu and click on “Export songs”.",
    "app.manual.6-export.step10-1": "As you can see it creates folder and one ZIP file with all songs.",
    "app.manual.6-export.step10-2": "Where are my exported files?",
    "app.manual.6-export.step11": "Open the App “Files”, go to “On My iPad” and click on SongTextBook.",
    "app.manual.6-export.step12": "Click on Export Folder.",
    "app.manual.6-export.step13": "And here is our new “test 1” folder.",
    "app.manual.6-export.step14": "Our songs are inside our new folder.",
    "app.manual.6-export.step15": "You can copy or move it wherever you want, for example to iCloud Drive.",
    "app.manual.6-export.step2": "Klick on “Files”.",
    "app.manual.6-export.step3": "Now you can select which songs you want to export, or you click on “Select all”.",
    "app.manual.6-export.step4": "After selecting songs for export (backup) klick on",
    "app.manual.6-export.step5": "Now you have to select in which folder you want to “Export” it.\nYou can also create new folder, if you want, or you click on “Export Folder”.",
    "app.manual.6-export.step6": "",
    "app.manual.6-export.step7": "",
    "app.manual.6-export.step8": "Click on new folder to go inside.",
    "app.manual.6-export.step9": "To save it in new folder, click on save icon ",
    "app.manual.6-export.title": "Export",
    "app.manual.7-settings.title": "Settings",
    "app.manual.8-helpful.filter": "Filter songs",
    "app.manual.8-helpful.navigation": "Song menu",
    "app.manual.8-helpful.title": "Helpful videos",
    "app.manual.8-helpful.transpose": "Transpose song accord",
    "app.manual.nav.export": "Export Songs",
    "app.manual.nav.helpful": "Helpful",
    "app.manual.nav.import": "Import Songs",
    "app.manual.nav.managbooks": "Manage books",
    "app.manual.nav.managesets": "Manage sets",
    "app.manual.nav.mangesongs": "Manage songs",
    "app.manual.nav.menu": "Menu",
    "app.manual.nav.newsong": "New song",
    "app.manual.nav.settings": "Settings",
    "app.manual.nav.start": "Start",
    "app.navbar.gallery": "Gallery",
    "app.navbar.home": "Home",
    "app.navbar.impressum": "About",
    "app.navbar.login": "Login",
    "app.navbar.logout": "Logout",
    "app.navbar.manual": "Manual",
    "app.navbar.privacy": "Privacy Policy",
    "app.navbar.support": "Support",
    "app.support.autoId": "auto ID :",
    "app.support.confirm": "Confirm",
    "app.support.fromular.email": "Your email address",
    "app.support.fromular.msg": "Message",
    "app.support.fromular.name": "Name",
    "app.support.fromular.submit": "Submit",
    "app.support.lead": "If you have any questions about Song TestBook APP, please contact us!",
    "app.support.ouremial": "retype email here",
    "app.support.protectemail": "For protecting us from SPAM mail you have to retype auto ID! ",
    "app.support.shownId": "add here shown auto ID"
  };
  _exports.default = _default;
});