define("song-text-book/controllers/manual/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    activeLocale: Ember.computed.readOnly('intl.locale'),
    language: Ember.computed('activeLocale', function () {
      return this.activeLocale[0];
    }),
    pdfFile: Ember.computed('language', function () {
      return "/pdf/STB-HowTo-import-" + this.language + ".pdf";
    })
  });

  _exports.default = _default;
});