define("song-text-book/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+up9rfxg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container text-white\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container rounded mb-4 p-4 bg-dark3 text-white\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"Account\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "song-text-book/templates/account.hbs"
    }
  });

  _exports.default = _default;
});