define("song-text-book/controllers/application", ["exports", "song-text-book/config/environment", "ember-intl/hydrate"], function (_exports, _environment, _hydrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service()
  });

  _exports.default = _default;
});